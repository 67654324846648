import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const handleRouteHome = () => {
    navigate("/");
  };

  return (
    <nav className="fixed bg-white w-full z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <img
            onClick={handleRouteHome}
            src="/assets/images/banner.png"
            alt=""
            className="object-contain w-[200px] h-[50px]"
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
