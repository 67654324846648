import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../provider/AuthProvider";
import axios from "axios";
import Navbar from "../Navbar";
import clsx from "clsx";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import { DocumentIcon } from "@heroicons/react/24/outline";

export const Download = () => {
  const { deviceId } = useContext(AuthContext);

  const [email, setEmail] = useState(null);
  const [taskID, setTaskID] = useState(null);
  const [document, setDocument] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const handleCheckout = async () => {
    const formData = new FormData();

    formData.append("email", email);
    formData.append("task_id", taskID);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}payment/checkout`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          deviceId: deviceId,
        },
      })
      .then((response) => {
        const newWindow = window.open(
          response.data.redirect_url,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownload = () => {
    try {
      const newWindow = window.open(document, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("task_id")) {
      setTaskID(query.get("task_id"));
    }
  }, [taskID]);

  useEffect(() => {
    if (taskID) {
      const docRef = doc(db, "roaster_task", taskID);
      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();

            if (data.payment_status) {
              setPaymentStatus(data.payment_status);
            }

            if (data.report_file) {
              setDocument(data.report_file);
            }

            if (data.email) {
              setEmail(data.email);
            }
          }
        },
        (error) => console.error("Error with real-time listener:", error)
      );

      return () => unsubscribe();
    }
  }, [taskID]);

  return (
    <>
      <Navbar />
      <section className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-8">
        <div className="grid grid-cols-1 gap-y-5 items-center justify-center place-items-center">
          <img
            src="/assets/images/banner.png"
            alt=""
            className="object-contain w-[300px] h-[90px]"
          />
          <div
            className={clsx(
              "grid grid-cols-1 gap-y-6 p-10 bg-white rounded-xl h-fit w-full max-w-2xl items-center justify-center place-items-center"
            )}
          >
            {paymentStatus === "succeeded" && (
              <p className="w-full text-sm font-bold text-center text-white bg-green-500 p-2 rounded-md">
                Your payment went through. Your report is being processed and
                sending to your email
              </p>
            )}

            {paymentStatus === "canceled" && (
              <p className="w-full text-sm font-bold text-center text-white bg-red-500 p-2 rounded-md">
                There was an issue with your payment. Please verify your card
                information.
              </p>
            )}

            <div
              className={clsx(
                "grid grid-cols-1 gap-y-1 items-center justify-center place-items-center"
              )}
            >
              <p className="text-3xl font-bold text-center">You're All Set</p>
              <p className="text-base font-normal text-center">
                How would you like to access your roast report?
              </p>
            </div>

            <input
              type="text"
              name="email"
              value={email}
              placeholder="Email"
              className="border border-black/30 w-full rounded-md h-12 p-3 placeholder:text-black placeholder:text-base focus:ring-primary focus:border-primary focus:outline-primary"
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />

            <button
              disabled={!email || !taskID}
              onClick={handleCheckout}
              className={
                "flex w-full border bg-primary disabled:bg-primary/20 border-primary text-white text-base font-bold text-center py-4 px-8 rounded-lg cursor-pointer items-center justify-center gap-x-3"
              }
            >
              {"Send PDF to Email"}
            </button>

            {document && (
              <>
                <p className="text-base font-normal text-center">or</p>
                <button
                  disabled={!taskID && !document}
                  onClick={handleDownload}
                  className={
                    "flex w-full border bg-white disabled:bg-primary/20 border-primary text-primary text-base font-bold text-center py-4 px-8 rounded-lg cursor-pointer items-center justify-center gap-x-3"
                  }
                >
                  <DocumentIcon className="h-6 w-6" />
                  {"Download PDF"}
                </button>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
