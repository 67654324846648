import { createBrowserRouter } from "react-router-dom";
import Upload from "../features/upload/Upload";
import { Download } from "../features/download/Download";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Upload />,
  },
  {
    path: "/download",
    element: <Download />,
  },
  {
    path: "*",
    element: <div />,
  },
]);

export default router;
