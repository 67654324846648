import { XMarkIcon } from "@heroicons/react/20/solid";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

export const DropZone = ({ onChange }) => {
  const [files, setFiles] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = (acceptedFiles) => {
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];

    const maxSizeInBytes = 20 * 1024 * 1024; // 20 MB

    if (acceptedFiles[0].size > maxSizeInBytes) {
      setErrorMessage("Max file size is 20MB per upload");
      setFiles(null);
      return;
    }

    if (!allowedTypes.includes(acceptedFiles[0].type)) {
      setErrorMessage(
        "Invalid file type. Please select a PNG,JPEG,JPG or PDF file."
      );
      setFiles(null);
      return;
    }

    setErrorMessage("");
    setFiles(acceptedFiles[0]);
    onChange(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf",
    multiple: false,
  });

  return (
    <div className="grid grid-cols-1 gap-y-4">
      {errorMessage && (
        <p className="text-base font-semibold text-primary">{errorMessage}</p>
      )}

      <div {...getRootProps()} className={`flex justify-center rounded-md`}>
        <input
          {...getInputProps()}
          type="file"
          accept="image/jpeg,image/jpg,image/png,application/pdf"
          multiple={false}
        />

        <button
          className={
            "flex w-full h-20 border border-primary text-primary text-lg font-bold text-center py-4 px-8 rounded-lg cursor-pointer items-center justify-center gap-x-3 hover:bg-white/30 hover:border-2"
          }
        >
          <ArrowUpTrayIcon className="h-5 w-5" />
          {"Drop File Here or Click to Upload"}
        </button>
      </div>

      {files !== null && (
        <div className="flex w-full gap-x-2 p-2 border border-white bg-primary rounded-lg items-center justify-between">
          <p className="text-base font-medium text-white">{files.name}</p>

          <button
            onClick={() => {
              onChange(null);
              setFiles(null);
            }}
          >
            <XMarkIcon className="w-5 h-5 fill-white cursor-pointer" />
          </button>
        </div>
      )}
    </div>
  );
};
