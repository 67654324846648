import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";

export const SelectLanguage = ({ languages, onChange }) => {
  const [selected, setSelected] = useState(null);

  const handleChange = (value) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      <ListboxButton
        className={clsx(
          "relative block w-full h-12 py-1.5 pr-8 pl-3 text-left text-base text-black bg-white",
          "border border-black/30 rounded-md",
          "items-center justify-center",
          "focus:ring-primary focus:border-primary focus:outline-primary"
        )}
      >
        {selected?.name ?? "Select Language"}
        <ChevronDownIcon
          className="group pointer-events-none absolute top-4 right-2.5 size-4 fill-black/60"
          aria-hidden="true"
        />
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        className={clsx(
          "w-[var(--button-width)] rounded-md border bg-white border-black/30 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
          "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
        )}
      >
        {languages.map((language) => (
          <ListboxOption
            key={language.id}
            value={language}
            className="group flex cursor-default items-center gap-2 rounded-lg py-2 px-3 select-none data-[focus]:bg-black/10"
          >
            <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible" />
            <div className="text-sm text-black">{language.name}</div>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
