import { SelectLanguage } from "./components/SelectLanguage";
import { DropZone } from "./components/DropZone";
import { FireIcon, NewspaperIcon, ShareIcon } from "@heroicons/react/20/solid";
import Navbar from "../Navbar";
import { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/cooking.json";
import axios from "axios";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import { AuthContext } from "../../provider/AuthProvider";
import { createSearchParams, useNavigate } from "react-router-dom";

const Upload = () => {
  const { deviceId } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [language, setLanguage] = useState(null);
  const [result, setResult] = useState(null);
  const [output, setOutput] = useState(null);
  const [status, setStatus] = useState(null);
  const [languages, setLanguages] = useState([]);

  const [count, setCount] = useState(0);

  const [estimatedTime, setEstimatedTime] = useState(null);

  const handleUpload = async () => {
    try {
      const startTime = Date.now();

      const formData = new FormData();

      formData.append("name", name);
      formData.append("lang", language.id);
      formData.append("file", file);

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}job/runasync/extract`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              deviceId: deviceId,
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              const currentTime = Date.now();
              const elapsedTime = currentTime - startTime;
              const remainingTime = ((100 - progress) / progress) * elapsedTime;
              setEstimatedTime(remainingTime);
            },
          }
        )
        .then((response) => {
          // Handle successful file upload response
          setIsUploading(true); // Show the loading spinner

          const endTime = Date.now(); // End time for measuring response time
          const responseTimeInSeconds = (endTime - startTime) / 1000;
          setEstimatedTime(responseTimeInSeconds);

          setResult(response.data);

          setTimeout(() => {
            setIsUploading(false); // Hide the loading spinner after a simulated delay
            // Add any further actions you want to take after successful upload and response
          }, 2000); // Simulated delay of 2 seconds
        })
        .catch((error) => {
          setResult(null);
          setIsUploading(false);
          alert(error?.response?.data?.message ?? "Something error on server");
        });
    } catch (error) {
      setResult(null);
      setIsUploading(false);
      alert(error?.response?.data?.message ?? "Something error on server");
    }

    // Set a timeout to estimate response time
    setTimeout(() => {
      if (!estimatedTime) {
        setEstimatedTime(null); // Reset responseTime when the response is still not ready
      }
    }, 5000); // Wait for 5 seconds before assuming the response is not ready
  };

  const handleRouteToDownload = () => {
    navigate({
      pathname: "/download",
      search: createSearchParams({ task_id: result.id }).toString(),
    });
  };

  const fetchLanguageData = async () => {
    try {
      const docRef = doc(db, "roaster_config", "general");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();

        let list = [];

        data.languages.forEach((e) => {
          list.push({
            id: e.id,
            name: e.name,
          });
        });

        setLanguages(list || []);
      } else {
        setLanguages([]);
      }
    } catch (error) {
      setLanguages([]);
    }
  };

  useEffect(() => {
    fetchLanguageData();
  }, []);

  useEffect(() => {
    if (result) {
      const docRef = doc(db, "roaster_task", result.id);
      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();

            setStatus(data.status);
            if (data.output) {
              setOutput(JSON.parse(data.output));
            }
          }
        },
        (error) => console.error("Error with real-time listener:", error)
      );

      return () => unsubscribe();
    }
  }, [result]);

  useEffect(() => {
    const itemsRef = collection(db, "roaster_task");

    const unsubscribe = onSnapshot(itemsRef, (snapshot) => {
      const length = snapshot.docs.length;
      const allCount = 1023 + length;
      setCount(allCount);
    });

    return () => unsubscribe();
  }, [result]);

  return (
    <>
      <Navbar />
      <section className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-8">
        <div className="flex items-center justify-center">
          <img
            src="/assets/images/banner.png"
            alt=""
            className="object-contain w-[300px] h-[90px]"
          />
        </div>
        <div className="grid grid-cols-1 gap-y-2">
          <p className="text-xl text-black text-center">
            "We don't hold back... because your bank statement is begging to be
            roasted!"
          </p>
          <p className="text-lg text-black text-center">
            🔥Upload Now, Face the Brutal Truth! 🔥
          </p>
          {count !== 0 && (
            <p className="text-base text-red-500 text-center font-medium">
              {count} user have been roasted
            </p>
          )}
        </div>
        {(!result || status !== "COMPLETED") && (
          <div className="grid grid-cols-1 gap-6">
            <div className="grid grid-cols-1 gap-y-2">
              <p className="text-base font-medium">Name</p>
              <input
                type="text"
                name="name"
                placeholder="Fill Your Name"
                className="border border-black/30 w-full rounded-md h-12 p-3 placeholder:text-black placeholder:text-base focus:ring-primary focus:border-primary focus:outline-primary"
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <p className="text-base font-medium">Roast Language</p>
              <SelectLanguage
                languages={languages}
                onChange={(value) => {
                  setLanguage(value);
                }}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <p className="text-base font-medium">Upload File</p>
              <DropZone
                onChange={(value) => {
                  setFile(value);
                }}
              />
            </div>

            <p className="text-base font-medium">
              PDF, PNG & JPG format are supported. Maximum size 20MB per upload
            </p>

            <button
              disabled={
                !name ||
                !language ||
                !file ||
                isUploading ||
                (status !== "COMPLETED" && status !== null)
              }
              onClick={handleUpload}
              className={
                "flex w-full h-14 border bg-primary disabled:bg-primary/20 border-primary text-white text-lg font-bold text-center py-4 px-8 rounded-lg cursor-pointer items-center justify-center gap-x-3"
              }
            >
              <FireIcon className="h-6 w-6" />
              {"Start Roasting"}
            </button>

            {(isUploading || (status !== "COMPLETED" && status !== null)) && (
              <div className="grid grid-cols-1 gap-5">
                <p className="text-lg font-bold text-center text-primary">
                  Cooking Your Statement – Because Your Finances Deserve a Good
                  Laugh 😂...
                </p>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={60}
                  width={60}
                />
              </div>
            )}
          </div>
        )}

        {result && status === "COMPLETED" && (
          <div className="grid grid-cols-1 gap-6 p-4">
            <div className="grid grid-cols-1 gap-3">
              <p className="text-lg font-bold text-black: text-center">
                Cooking Result
              </p>
              <textarea
                value={output?.output.overal_roast ?? "-"}
                rows="4" // Optional: defines the number of visible text lines
                cols="50" // Optional: defines the width of the text area
                className="border border-primary rounded-lg text-base p-3 focus:ring-primary focus:border-primary focus:outline-primary"
              />
            </div>

            <div className="relative grid w-full h-[306px]">
              <img
                className="absolute w-full h-[306px] border-2 border-gray-500 rounded-lg object-fill"
                src="/assets/images/report_blur.jpg"
                alt=""
              />
              <div className="absolute h-[306px] p-2 top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-y-4 justify-items-center items-center content-center">
                <p className="text-xl font-bold text-center">
                  Want the complete story? Get the full report from us 📄
                </p>
                <button
                  onClick={handleRouteToDownload}
                  className="flex w-fit gap-x-3 items-center justify-center bg-blue-700 rounded-lg px-6 py-3"
                >
                  <NewspaperIcon className="w-5 h-5 fill-white" />
                  <p className="text-base font-bold text-white text-center">
                    Get the Full Breakdown
                  </p>
                </button>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:flex w-full gap-4 content-center items-center justify-center place-items-center">
              <button
                onClick={() => {
                  setResult(null);
                  setFile(null);
                  setLanguage(null);
                  setName(null);
                  setStatus(null);
                  setOutput(null);
                  setIsUploading(false);
                }}
                className="flex w-[250px] gap-x-3 items-center justify-center bg-primary rounded-lg px-6 py-3"
              >
                <FireIcon className="w-5 h-5 fill-white" />
                <p className="text-base font-bold text-white text-center">
                  Another Round!
                </p>
              </button>
              <button className="flex w-[250px] gap-x-3 items-center justify-center bg-blue-700 rounded-lg px-6 py-3">
                <ShareIcon className="w-5 h-5 fill-white" />
                <p className="text-base font-bold text-white text-center">
                  Share
                </p>
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Upload;
